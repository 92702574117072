import { For, Match, Show, Switch } from "solid-js";
import { urlRs } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { TermEntityReferenceField } from "~/types/drupal_jsonapi";

import "./NouveauNeufLogos.css";

export default function NouveauNeufLogos(props: {
  zone: string;
  segmentsMkg: TermEntityReferenceField[];
}) {
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <div class="logos-nouveau-neuf" data-test="logos-nn">
        <For each={props.segmentsMkg}>
          {(segmentMkg: TermEntityReferenceField) => (
            <span
              class={`logo logo-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
              data-test={`logo-nn-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
            >
              <Show when={props.zone === "program"}>
                <Switch>
                  <Match when={segmentMkg.name === "Access"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-access/",
                      )}
                      title="Access"
                    >
                      <span
                        class="pastille-svg"
                        innerHTML={
                          settingsContext.marketing_segments_terms.access
                            .field_logo_main_svg
                        }
                      />
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Avantages"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-avantages/",
                      )}
                      title="Cogedim Avantages"
                    >
                      <span
                        class="pastille-svg"
                        innerHTML={
                          settingsContext.marketing_segments_terms.invest
                            .field_logo_main_svg
                        }
                      />
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-signature/",
                      )}
                      title="Signature"
                    >
                      <span
                        class="pastille-svg"
                        innerHTML={
                          settingsContext.marketing_segments_terms.signature
                            .field_logo_main_svg
                        }
                      />
                    </a>
                  </Match>
                </Switch>
              </Show>
              <Show when={props.zone === "card"}>
                <Switch>
                  <Match when={segmentMkg.name === "Access"}>
                    <span
                      class="pastille-svg"
                      innerHTML={
                        settingsContext.marketing_segments_terms.access
                          .field_logo_main_svg
                      }
                    />
                  </Match>
                  <Match when={segmentMkg.name === "Avantages"}>
                    <span
                      class="pastille-svg"
                      innerHTML={
                        settingsContext.marketing_segments_terms.invest
                          .field_logo_main_svg
                      }
                    />
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <span
                      class="pastille-svg"
                      innerHTML={
                        settingsContext.marketing_segments_terms.signature
                          .field_logo_main_svg
                      }
                    />
                  </Match>
                </Switch>
              </Show>
            </span>
          )}
        </For>
      </div>
    </>
  );
}
